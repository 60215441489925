<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import Editor from "@tinymce/tinymce-vue";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Dashboard",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        editor: Editor,
    },
    data() {
        return {
            title: "Detail Permohonan",
            items: [
                {
                    text: "Dashboards",
                    active: true,
                },
            ],
            axiosCatchError: null,
            // komponen detail permohonan
            permohonan_id: this.$route.params.permohonan_id,
            permohonan: [],
            permohonan_data_teknis: null,
            permohonan_dokumen: [],
            permohonan_timeline: [],
            dokumen_proses: [],
            dokumen_hasil: [],
            // komponen verifikasi
            modal_verifikasi: false,
            status_verifikasi: "",
            catatan_verifikasi: "",
            // komponen detail status
            status_sekarang: [],
            status_selanjutnya: [],
            // komponen verifikasi dokumen
            modal_verifikasi_dokumen: false,
            count_dokumen_terima: 0,
            count_dokumen_tolak: 0,
            count_dokumen_all: 0,
            catatan_verifikasi_dokumen: "",
            key_verifikasi_dokumen: null,
            status_verifikasi_dokumen: null,
            hak_akses: false,
            // kompoenen sip
            sip_menimbang: "",
            sip_mengingat: "",
            sip_memperhatikan: "",
            sip_memutuskan: "",
            sip_salinan: "",
            konfig_sk_permohonan: false,
            passphrase_tte: "",
            // komponen upload dokumen karantina
            modal_upload_karantina: false,
            list_dokumen_karantina: [],
            nomor_rekomendasi_karantina: null,
            tanggal_rekomendasi_karantina: null,
        };
    },
    mounted() {
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        let self = this;
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/detail", {
                params: {
                    permohonan_id: self.permohonan_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var res_fix = response.data.data;
                self.permohonan = res_fix.permohonan;
                // permohonan_data_teknis
                self.permohonan_timeline = res_fix.permohonan_timeline;
                self.status_sekarang = res_fix.status_sekarang;
                self.status_selanjutnya = res_fix.status_selanjutnya;
                self.permohonan_dokumen = res_fix.permohonan_dokumen;
                self.dokumen_proses = res_fix.dokumen_proses;
                self.dokumen_hasil = res_fix.dokumen_hasil;
                self.list_dokumen_karantina = res_fix.dokumen_karantina;
                self.hak_akses = res_fix.hak_akses;
                var count_dokumen_terima = 0;
                var count_dokumen_tolak = 0;
                $.each(res_fix.permohonan_dokumen, function (indexInArray, valueOfElement) {
                    if (valueOfElement?.permohonan_dokumen_verifikasi[0]?.pdv_status == "terima") {
                        count_dokumen_terima = count_dokumen_terima + 1;
                    }
                    if (valueOfElement?.permohonan_dokumen_verifikasi[0]?.pdv_status == "tolak") {
                        count_dokumen_tolak = count_dokumen_tolak + 1;
                    }
                });
                self.count_dokumen_terima = count_dokumen_terima;
                self.count_dokumen_tolak = count_dokumen_tolak;
                self.count_dokumen_all = self.permohonan_dokumen.length;
                self.sip_menimbang = res_fix.konfig_sk.menimbang;
                self.sip_mengingat = res_fix.konfig_sk.mengingat;
                self.sip_memperhatikan = res_fix.konfig_sk.memperhatikan;
                self.sip_memutuskan = res_fix.konfig_sk.memutuskan;
                self.sip_salinan = res_fix.konfig_sk.salinan;
                self.konfig_sk_permohonan = res_fix.konfig_sk.konfig_sk_permohonan;
                self.tanggal_rekomendasi_karantina = res_fix.permohonan.p_tanggal_rekomendasi_karantina;
                self.nomor_rekomendasi_karantina = res_fix.permohonan.p_nomor_rekomendasi_karantina;
            })
            .catch((e) => {
                this.axiosCatchError = e.response;
                // Swal.close()
            });
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/data-teknis", {
                params: {
                    permohonan_id: self.permohonan_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                self.permohonan_data_teknis = response.data;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response;
                Swal.close();
            });
    },
    methods: {
        getFile(files) {
            var win = window.open(process.env.VUE_APP_BACKEND_URL + files, "_blank");
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert("Please allow popups for this website");
            }
        },
        getFileFullUrl(files) {
            var win = window.open(files, "_blank");
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert("Please allow popups for this website");
            }
        },
        showModalVerifikasi(status_verifikasi) {
            this.status_verifikasi = status_verifikasi;
            this.modal_verifikasi = true;
        },
        prosesVerifikasi() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            this.modal_verifikasi = false;
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/persetujuan-permohonan",
                    {
                        permohonan_id: self.permohonan_id,
                        status_verifikasi: self.status_verifikasi,
                        catatan_verifikasi: self.catatan_verifikasi,
                        passphrase_tte: self.passphrase_tte,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            window.location.reload();
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        verifikasiDokumen(key, status_verifikasi_dokumen) {
            this.key_verifikasi_dokumen = key;
            this.status_verifikasi_dokumen = status_verifikasi_dokumen;
            if (status_verifikasi_dokumen == "tolak") {
                this.modal_verifikasi_dokumen = true;
            } else {
                this.prosesVerifikasiDokumen();
            }
        },
        prosesVerifikasiDokumen() {
            let self = this;
            self.modal_verifikasi_dokumen = false;
            var key = self.key_verifikasi_dokumen;
            var status_verifikasi_dokumen = self.status_verifikasi_dokumen;
            var dokumen_persyaratan = self.permohonan_dokumen[key];
            self.permohonan_dokumen[key].is_loading = true;
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/persetujuan-dokumen",
                    {
                        permohonan_id: self.permohonan_id,
                        dokumen_persyaratan_id: dokumen_persyaratan.pd_id,
                        status_verifikasi_dokumen: status_verifikasi_dokumen,
                        catatan_verifikasi_dokumen: self.catatan_verifikasi_dokumen,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.permohonan_dokumen[key].is_loading = false;
                    dokumen_persyaratan.permohonan_dokumen_verifikasi[0] = res_fix.dokumen_verifikasi;
                    self.permohonan_dokumen[key] = dokumen_persyaratan;
                    var count_dokumen_terima = 0;
                    var count_dokumen_tolak = 0;
                    $.each(self.permohonan_dokumen, function (indexInArray, valueOfElement) {
                        if (valueOfElement?.permohonan_dokumen_verifikasi[0]?.pdv_status == "terima") {
                            count_dokumen_terima = count_dokumen_terima + 1;
                        }
                        if (valueOfElement?.permohonan_dokumen_verifikasi[0]?.pdv_status == "tolak") {
                            count_dokumen_tolak = count_dokumen_tolak + 1;
                        }
                    });
                    self.count_dokumen_terima = count_dokumen_terima;
                    self.count_dokumen_tolak = count_dokumen_tolak;
                    self.count_dokumen_all = self.permohonan_dokumen.length;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storeKonfigSIP() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            this.modal_verifikasi = false;
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/store-sip",
                    {
                        ps_permohonan_id: self.permohonan_id,
                        ps_menimbang: self.sip_menimbang,
                        ps_mengingat: self.sip_mengingat,
                        ps_memperhatikan: self.sip_memperhatikan,
                        ps_memutuskan: self.sip_memutuskan,
                        ps_salinan: self.sip_salinan,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            window.location.reload();
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        inputFileDocument(keys) {
            let self = this;
            if ($("#files-doc-" + keys)[0].files[0]) {
                if ($("#files-doc-" + keys)[0].files[0].size < 2242880) {
                    $("#duloading-" + keys).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var dokumen_karantina_upload = this.list_dokumen_karantina[keys];
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-" + keys)[0].files[0]);
                    data.append("permohonan_id", this.permohonan_id);
                    data.append("dokumen_karantina", JSON.stringify(dokumen_karantina_upload));
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/upload-dokumen-proses",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            dokumen_karantina_upload.path = response.data.data.file;
                            urlres += response.data.data.file;
                            $("#duloading-" + keys).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>');
                            self.list_dokumen_karantina[keys] = dokumen_karantina_upload;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-" + keys).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + " </span>");
                            }
                            console.log(e.response);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        addRowDokumenKarantina() {
            this.list_dokumen_karantina.push({
                nama_dokumen: null,
                path: null,
            });
        },
        removeDokumenKarantina(key_deleted) {
            this.list_dokumen_karantina.splice(key_deleted, 1);
        },
        storeDokumenKarantina() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            this.modal_verifikasi = false;
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/store-dokumen-karantina",
                    {
                        permohonan_id: self.permohonan_id,
                        dokumen_karantina: JSON.stringify(self.list_dokumen_karantina),
                        nomor_rekomendasi_karantina: self.nomor_rekomendasi_karantina,
                        tanggal_rekomendasi_karantina: self.tanggal_rekomendasi_karantina,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman segera diperbarui",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            window.location.reload();
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
    },
};
</script>
<template>
    <Layout>
        <div class="row">
            <div class="col-lg-12">
                <b-tabs pills content-class="py-3 text-muted">
                    <b-tab active class="border-0">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Detail Permohonan</span>
                        </template>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="my-0">Detail Permohonan {{ permohonan.p_kode }}</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-center"><b>Data Umum</b></div>
                                        <table class="table table-sm table-borderless mb-0 table-striped table-hover">
                                            <tbody>
                                                <tr v-if="permohonan?.p_id_izin">
                                                    <th style="width: 250px">Id Izin</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        {{ permohonan.p_id_izin }}
                                                    </td>
                                                </tr>
                                                <tr v-if="permohonan?.p_kode_izin">
                                                    <th style="width: 250px">Kode Izin</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        {{ permohonan.p_kode_izin }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style="width: 250px">Nomor Surat Permohonan</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        {{ permohonan.p_nomor_surat_permohonan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style="width: 250px">Tanggal Surat</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        {{ permohonan.p_tanggal_surat_permohonan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style="width: 250px">Perihal</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        {{ permohonan.p_perihal }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Jenis Perizinan</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        <span v-if="permohonan?.perizinan?.perizinanjenis?.mpj_nama">{{ permohonan.perizinan.perizinanjenis.mpj_nama }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Perizinan</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        <span v-if="permohonan?.perizinan?.mp_nama">{{ permohonan.perizinan.mp_nama }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Sub Perizinan</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        <span v-if="permohonan?.perizinansub?.mps_nama">{{ permohonan.perizinansub.mps_nama }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Status Permohonan</th>
                                                    <th style="width: 15px">:</th>
                                                    <td>
                                                        <badge v-if="status_sekarang.mspk_status_kategori == 'Draft'" class="badge bg-warning">{{ status_sekarang.mspk_status_kategori }}</badge>
                                                        <badge v-else-if="status_sekarang.mspk_status_kategori == 'Selesai'" class="badge bg-success">{{ status_sekarang.mspk_status_kategori }}</badge>
                                                        <badge v-else-if="status_sekarang.mspk_status_kategori == 'Perbaikan'" class="badge bg-warning">{{ status_sekarang.mspk_status_kategori }}</badge>
                                                        <badge v-else-if="status_sekarang.mspk_status_kategori == 'Ditolak'" class="badge bg-warning">{{ status_sekarang.mspk_status_kategori }}</badge>
                                                        <badge v-else class="badge bg-info">{{ permohonan.status_permohonan }} - {{ status_sekarang?.mspk_role_nama }}</badge>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-center"><b>Data Teknis</b></div>
                                        <div v-html="permohonan_data_teknis"></div>
                                        <div class="text-center"><b>Dokumen Persyaratan</b></div>
                                        <table class="table table-sm table-bordered mb-0">
                                            <thead class="bg-dark text-white text-center">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>Tanggal Awal</th>
                                                    <th>Tanggal Akhir</th>
                                                    <th>File</th>
                                                    <th v-if="status_sekarang.mspk_is_verifikasi_dokumen == 't' && hak_akses">Aksi</th>
                                                    <th>Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(permohonan_dokumen_val, permohonan_dokumen_key) in permohonan_dokumen" :key="permohonan_dokumen_key">
                                                    <td>{{ permohonan_dokumen_key + 1 }}</td>
                                                    <td>
                                                        <span v-if="permohonan_dokumen_val.dokumen_persyaratan.mdp_nama">{{ permohonan_dokumen_val.dokumen_persyaratan.mdp_nama }}</span>
                                                    </td>
                                                    <td>
                                                        <div v-if="permohonan_dokumen_val.pd_mdp_tanggal_awal">{{ permohonan_dokumen_val.pd_mdp_tanggal_awal }}</div>
                                                        <div v-else>Data Tidak Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="permohonan_dokumen_val.pd_mdp_tanggal_akhir">{{ permohonan_dokumen_val.pd_mdp_tanggal_akhir }}</div>
                                                        <div v-else>Data Tidak Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div class="btn btn-warning btn-sm" v-on:click="getFile(permohonan_dokumen_val.pd_path)"><i class="fa fa-eye"></i> Lihat</div>
                                                    </td>
                                                    <td v-if="status_sekarang.mspk_is_verifikasi_dokumen == 't' && hak_akses">
                                                        <div v-if="permohonan_dokumen_val?.is_loading"><i class="fa fa-refresh fa-spin"></i> Loading...</div>
                                                        <div v-else>
                                                            <div class="button-items" v-if="permohonan_dokumen_val?.permohonan_dokumen_verifikasi[0]?.pdv_status == 'terima'">
                                                                <b-button variant="info" size="sm" v-on:click="verifikasiDokumen(permohonan_dokumen_key, 'roleback')"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                <b-button variant="success" size="sm"><i class="bx bx-check"></i> Diterima</b-button>
                                                            </div>
                                                            <div class="button-items" v-else-if="permohonan_dokumen_val?.permohonan_dokumen_verifikasi[0]?.pdv_status == 'tolak'">
                                                                <b-button variant="info" size="sm" v-on:click="verifikasiDokumen(permohonan_dokumen_key, 'roleback')"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                <b-button variant="danger" size="sm"><i class="bx bx-x"></i> Ditolak</b-button>
                                                                <br />
                                                            </div>
                                                            <div class="button-items" v-else>
                                                                <b-button variant="danger" size="sm" v-on:click="verifikasiDokumen(permohonan_dokumen_key, 'tolak')"><i class="bx bx-x"></i> Tolak</b-button>
                                                                <b-button variant="success" size="sm" v-on:click="verifikasiDokumen(permohonan_dokumen_key, 'terima')"><i class="bx bx-check"></i> Terima</b-button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ permohonan_dokumen_val?.permohonan_dokumen_verifikasi[0]?.pdv_keterangan }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-center"><b>Dokumen Proses</b></div>
                                        <table class="table table-sm table-bordered mb-0">
                                            <thead class="bg-dark text-white text-center">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(dokumen_proses_val, dokumen_proses_key) in dokumen_proses" :key="dokumen_proses_key">
                                                    <td>{{ dokumen_proses_key + 1 }}</td>
                                                    <td>{{ dokumen_proses_val.nama_dokumen }}</td>
                                                    <td>
                                                        <div class="btn btn-warning btn-sm" v-on:click="getFileFullUrl(dokumen_proses_val.path)"><i class="fa fa-eye"></i> Lihat</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-center"><b>Dokumen Hasil</b></div>
                                        <table class="table table-sm table-bordered mb-0">
                                            <thead class="bg-dark text-white text-center">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(dokumen_hasil_val, dokumen_hasil_key) in dokumen_hasil" :key="dokumen_hasil_key">
                                                    <td>{{ dokumen_hasil_key + 1 }}</td>
                                                    <td>{{ dokumen_hasil_val.nama_dokumen }}</td>
                                                    <td>
                                                        <div class="btn btn-warning btn-sm" v-on:click="getFileFullUrl(dokumen_hasil_val.path)"><i class="fa fa-eye"></i> Lihat</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-4" v-if="status_sekarang.mspk_is_upload_dokumen_karantina == 't'">
                                        <div class="text-start">
                                            <b-button variant="primary" v-on:click="modal_upload_karantina = true"> <i class="bx bx-upload"></i> Upload Dokumen Karantina </b-button>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="text-end button-items" v-if="status_selanjutnya?.mspk_status_kategori == 'Proses' && hak_akses">
                                            <b-button variant="danger" v-on:click="showModalVerifikasi('Dibatalkan')"> <i class="bx bx-x"></i> Batalkan Permohonan </b-button>
                                            <b-button variant="warning" v-on:click="showModalVerifikasi('Perbaikan')"> <i class="bx bx-x"></i> Tolak Perbaiki </b-button>
                                            <b-button variant="primary" v-on:click="showModalVerifikasi('Proses')" v-if="(status_sekarang.mspk_is_verifikasi_dokumen == 't' && count_dokumen_terima == count_dokumen_all) || status_sekarang.mspk_is_verifikasi_dokumen == 'f'"> <i class="bx bx-check"></i> Verifikasi </b-button>
                                            <b-button variant="secondary" v-else> <i class="bx bx-check"></i> Verifikasi </b-button>
                                        </div>
                                        <div class="text-end button-items" v-else-if="status_selanjutnya?.mspk_status_kategori == 'Selesai' && hak_akses">
                                            <b-button variant="success" v-on:click="showModalVerifikasi('Selesai')"> <i class="bx bx-check"></i> Selesaikan </b-button>
                                        </div>
                                        <div class="" v-else>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="permohonan?.perizinan?.perizinanjenis?.mpj_id == 2 && status_sekarang.mspk_is_konfig_sk == 't' && hak_akses && permohonan.p_is_draft_sk == 't'">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Kelengkapan SK</span>
                        </template>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="my-0">Kelengkapan SK</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <b class="text-center"> Menimbang </b>
                                        <editor
                                            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                            :init="{
                                                height: 500,
                                                menubar: true,
                                                plugins: ['table', 'code'],
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                            }"
                                            v-model="sip_menimbang" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <b class="text-center"> Mengingat </b>
                                        <editor
                                            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                            :init="{
                                                height: 500,
                                                menubar: true,
                                                plugins: ['table', 'code'],
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                            }"
                                            v-model="sip_mengingat" />
                                    </div>
                                    <!-- <div class="col-md-12 mb-3">
                                        <b class="text-center"> Memperhatikan </b>
                                        <editor
                                            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                            :init="{
                                                height: 500,
                                                menubar: true,
                                                plugins: ['table', 'code'],
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                            }"
                                            v-model="sip_memperhatikan" />
                                    </div> -->
                                    <div class="col-md-12 mb-3">
                                        <b class="text-center"> Memutuskan </b>
                                        <editor
                                            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                            :init="{
                                                height: 500,
                                                menubar: true,
                                                plugins: ['table', 'code'],
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                            }"
                                            v-model="sip_memutuskan" />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <b class="text-center"> Salinan </b>
                                        <editor
                                            api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                            :init="{
                                                height: 500,
                                                menubar: true,
                                                plugins: ['table', 'code'],
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                            }"
                                            v-model="sip_salinan" />
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <b-button variant="primary" v-on:click="storeKonfigSIP()"> <i class="bx bx-save"></i> Simpan Kelengkapan SIP </b-button>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="card">
                    <div class="card-header"><h4 class="my-0">Linimasa</h4></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" style="overflow-x: scroll">
                                <ul class="timeline timeline-horizontal">
                                    <li class="timeline-item" v-for="(value_timelime, index_timelime) in permohonan_timeline" :key="index_timelime">
                                        <div class="timeline-badge danger" v-if="value_timelime.pt_status_kategori == 'ditolak' || value_timelime.pt_status_kategori == 'dibatalkan'">
                                            <i class="fa fa-times" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-badge success" v-else>
                                            <i class="fa fa-check" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <b class="timeline-title"> {{ value_timelime.role.role }} : {{ JSON.parse(value_timelime.pt_user_json).name }}</b>
                                                <p>
                                                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ value_timelime.pt_tanggal }}</small>
                                                </p>
                                            </div>
                                            <div class="badge bg-info">
                                                {{ JSON.parse(value_timelime.pt_status_json).msp_nama }}
                                            </div>
                                            <br />
                                            <div class="timeline-body" v-if="value_timelime.pt_catatan">
                                                <b>Catatan : </b><br />
                                                <p>{{ value_timelime.pt_catatan }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="modal_verifikasi" hide-footer centered header-class="border-0" title="Catatan Verifikasi">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3" role="group" v-if="this.status_sekarang.mspk_is_tte == 't'">
                            <label for="formrow-jenis-perizinan-pilihan" class="d-block">Passphrase</label>
                            <div class="">
                                <input type="text" class="form-control" v-model="passphrase_tte" />
                            </div>
                        </div>
                        <b-form-group class="mb-3" label="Catatan Verifikasi" label-for="formrow-jenis-perizinan-pilihan">
                            <textarea class="form-control" v-model="catatan_verifikasi"></textarea>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_verifikasi = false"> <i class="bx bx-x"></i> Tutup </b-button>
                <b-button variant="primary" v-on:click="prosesVerifikasi()"> <i class="bx bx-save"></i> Proses </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_verifikasi_dokumen" hide-footer centered header-class="border-0" title="Catatan Verifikasi Dokumen">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group class="mb-3" label="Catatan Verifikasi Dokumen">
                            <textarea class="form-control" v-model="catatan_verifikasi_dokumen"></textarea>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_verifikasi_dokumen = false"> <i class="bx bx-x"></i> Tutup </b-button>
                <b-button variant="primary" v-on:click="prosesVerifikasiDokumen()"> <i class="bx bx-save"></i> Proses </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_upload_karantina" hide-footer centered header-class="border-0" title="Upload Dokumen Karantina" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group class="mb-3" label="Nomor Rekomendasi Karantina" label-for="form-nomor_rekomendasi_karantina">
                            <input type="text" id="nomor_rekomendasi_karantina" class="form-control" v-model="nomor_rekomendasi_karantina" />
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group class="mb-3" label="Tanggal Rekomendasi Karantina" label-for="form-tanggal_rekomendasi_karantina">
                            <input type="date" id="tanggal_rekomendasi_karantina" class="form-control" v-model="tanggal_rekomendasi_karantina" />
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <table class="table table-sm table-bordered mb-0">
                            <thead class="bg-dark text-white text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Nama Dokumen</th>
                                    <th>File</th>
                                    <th style="width: 50px">
                                        <div class="btn btn-success btn-sm" v-on:click="addRowDokumenKarantina()"><i class="bx bx-plus"></i></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in list_dokumen_karantina" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="row.nama_dokumen" />
                                    </td>
                                    <td>
                                        <input type="file" class="form-control mb-0" :id="'files-doc-' + index" v-on:change="inputFileDocument(index)" accept=".pdf, .word, .excel, .png, .jpg" />
                                        <div class="respond-input-file float-left" :id="'duloading-' + index">
                                            <div v-on:click="getFile(row.path)" v-if="row.path" style="padding-left: 10px">
                                                <span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span>
                                            </div>
                                        </div>
                                        <br />
                                        <small class="float-right">*) Max File 2 MB</small>
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeDokumenKarantina(index)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_upload_karantina = false"> <i class="bx bx-x"></i> Tutup </b-button>
                <b-button variant="primary" v-on:click="storeDokumenKarantina()"> <i class="bx bx-save"></i> Proses </b-button>
            </div>
        </b-modal>
    </Layout>
</template>
